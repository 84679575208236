<template>
  <div>
    <PLoader v-if="!products.length" />

    <template v-else>
      <div
        class="flex flex-col items-center w-full px-3 py-2 mt-1 mb-2 border rounded-md md:flex-row"
        :class="{
          'bg-gradient-to-b from-gray-50 to-gray-200  border-gray-300':
            product.quantity === 0,
          'bg-green-100 border-green-500': product.quantity,
          'opacity-50': product.qtyMax === 0 && getProductQtyMin(product) === 0 || isLoading,
        }"
        v-for="(product, index) in products"
        :key="index"
      >
        <img
          alt=""
          :src="`${assetsBaseUrl}/media.ashx?${product.image}&template=product-image-69`"
        />

        <div class="text-center md:ml-4 md:text-left">
          <template v-if="isHE && product.qualityId === 'SORT'">
            <div class="font-medium">
              {{ product.qualityName }}
            </div>
          </template>

          <template v-else>
            <div class="font-medium">
              {{ product.productName }}
            </div>

            <div
              class="text-xs"
              v-if="!isExternal && (isGL && product.isPooling) === false"
            >
              {{ product.qualityName }}
              {{ product.treatmentIsDefault ? "" : product.treatmentName }}
            </div>
          </template>
        </div>

        <div class="flex flex-col items-center md:flex-1 md:flex-row md:justify-end">
          <div
            class="ml-4 text-center"
            v-if="product.qtyStockType === 'Receipt'"
          >
            <div class="font-medium">
              <PTranslation k="Common.General.PalletReceipt" />
            </div>
            <div class="text-sm">
              <PTranslation k="Common.General.qtyStock" />:
              {{ product.qtyStock }}
            </div>
          </div>

          <div
            class="ml-4 text-center"
            v-else-if="showBalances && product.isCommission"
          >
            <div class="font-medium">
              <PTranslation k="Common.General.Commission" />
            </div>
            <div
              class="text-sm"
              :class="{
                'text-green-500':
                  (location.showInternalStock && product.qtyStock < 0) ||
                  (!location.showInternalStock && product.qtyStock > 0),
                'text-red-500':
                  (location.showInternalStock && product.qtyStock > 0) ||
                  (!location.showInternalStock && product.qtyStock < 0),
              }"
              v-if="product.qtyStock !== null"
            >
              <template v-if="
                (location.showInternalStock && product.qtyStock < 0) ||
                (!location.showInternalStock && product.qtyStock > 0)
              ">
                <span class="font-semibold">
                  {{ product.qtyStock * (location.showInternalStock ? -1 : 1) }}
                </span>
                <span>
                  {{ $tk("Common.General.Credited").toLowerCase() }}
                </span>
                <span
                  class="text-red-500"
                  v-if="
                    product.noNegComm && product.qtyMax < product.qtyMaxOrder
                  "
                >
                  ({{ $tk("Common.General.Max").toLowerCase() }})
                </span>
              </template>
              <template v-else-if="
                (location.showInternalStock && product.qtyStock > 0) ||
                (!location.showInternalStock && product.qtyStock < 0)
              ">
                <span class="font-semibold">{{
                  product.qtyStock * (location.showInternalStock ? 1 : -1)
                  }}</span>
                <span> {{ $tk('Common.General.Owes').toLowerCase() }}</span>
              </template>
              <template v-else>
                <span v-html="$tk('Common.General.Balance')"></span>:
                <span class="font-semibold">{{ product.qtyStock }}</span>
              </template>
            </div>
          </div>

          <div
            class="flex items-baseline text-lg"
            v-if="!hidePricesSveCatC && showPrices && product.price > 0"
          >
            <span class="text-xs">{{ product.currencyId }}</span>
            <span class="ml-1 font-semibold">{{
              numberFormat(product.price, 2)
              }}</span>
          </div>

          <div v-if="hidePricesSveCatC">
            <div class="inline-flex items-center">
              <span class="text-xs">Prisuppgift</span>
              <PTooltip
                class="ml-1 text-xs"
                :content="'Ta kontakt med order@smartretur.se'"
              />
            </div>
          </div>

          <div
            class="ml-4 leading-none text-center md:w-20"
            :class="{ invisible: product.isBlocked }"
          >
            <template v-if="!product.qtyFree && product.qtyPick > 1">
              &#177;{{ product.qtyPick }}
            </template>
          </div>

          <template v-if="!product.isBlocked || loginUser.isSmartRetur">
            <div class="relative mt-2 md:mt-0">
              <div
                v-if="product.quantityExtra > 0"
                class="absolute z-10 mt-1 text-xs text-red-500 top-2 left-2"
                v-tooltip="{
                  content: $t('Common.General.OfWhichUnderOtherProducts', {
                    count: product.quantityExtra,
                    name: product.productName.toLowerCase(),
                  }),
                  delay: {
                    show: 100,
                    hide: 100,
                  },
                }"
              >
                <FontAwesomeIcon :icon="['fad', 'pallet-alt']" />
                {{ product.quantityExtra }}
              </div>

              <div
                v-if="product.isBlocked"
                class="absolute z-10 mt-1 text-xs text-red-500 top-2"
                :class="{
                  'left-10': product.quantityExtra > 0,
                  'left-2': !product.quantityExtra,
                }"
                v-tooltip="{
                  content: $t('OrderDialog.NotAvailableButAsSRYouCanOrder'),
                  delay: {
                    show: 100,
                    hide: 100,
                  },
                }"
              >
                <FontAwesomeIcon :icon="['far', 'exclamation-triangle']" />
              </div>

              <PNumber
                class="w-28"
                :disabled="disabled || (product.qtyMax === 0 && getProductQtyMin(product) === 0)"
                :value="product.quantity"
                :extra="product.quantityExtra"
                :min="allowNegativeVolume && product.qtyFree
                  ? -9999
                  : product.quantityExtra
                  "
                :max="product.qtyMax"
                :warn="product.qtyWarn"
                :warnMessage="$t('OrderDialog.WarningHighVolume')"
                :step="product.qtyFree ? 1 : product.qtyPick || 1"
                :wait="1000"
                @input="setQuantity(product, $event)"
              />
            </div>

            <div class="flex items-center p-2 md:p-0">
              <a
                href
                tabindex="-1"
                v-if="showFill"
                class="ml-4 text-sm underline"
                :class="{
                  'cursor-not-allowed': disabled,
                  invisible: product.qtyMaxOrder === 0,
                }"
                :disabled="disabled"
                @click.prevent="setQuantity100(product)"
                v-html="$tk('Common.General.Fill')"
              ></a>

              <a
                href
                tabindex="-1"
                class="ml-4 text-sm underline"
                :class="{
                  'cursor-not-allowed': disabled || product.quantity === 0,
                  'text-red-500': product.quantity > 0,
                }"
                :disabled="disabled || product.quantity === 0"
                @click.prevent="setQuantity0(product)"
                title="Tøm dette produktet fra bil"
              >
                <FontAwesomeIcon :icon="['far', 'trash-alt']" />
              </a>
            </div>
          </template>

          <template v-else>
            <slot
              name="blocked"
              :product="product"
            >
              <div class="text-sm text-center">
                <PTranslation k="Common.General.NotAvailable" />
              </div>
            </slot>
          </template>
        </div>
      </div>

    </template>
  </div>
</template>

<script>
import http from "@/utilities/http";
import { mapGetters } from "vuex";
import { find, forEach, get, map } from "lodash";

const key = (o) => `${o.productId}:${o.treatmentId}:${o.qualityId}`.toUpperCase();

export default {
  name: "PProductQuantitiesPicker",

  props: {
    value: { type: Array, default: () => [] },
    transactionType: { type: String, default: "TO" },
    orderId: { type: String, default: "" },
    isExternal: { type: Boolean, default: false },
    showBalances: { type: Boolean, default: true },
    showPrices: { type: Boolean, default: true },
    allowNegativeVolume: { type: Boolean, default: false },
    showFill: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    // TODO update external order
    // locationId: { type: String, required: true },
    locationTo: { type: Object, required: false },
    truckTypeId: { type: String, default: "" },
    noTransport: { type: Boolean, default: false },
  },
  data() {
    return {
      products: [],
      requestFailed: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters(["loginUser", "location", "customer"]),
    isGL() {
      return this.transactionType === "GL";
    },
    isTO() {
      return this.transactionType === "TO";
    },
    isHE() {
      return this.transactionType === "HE";
    },
    loadTriggers() {
      return {
        locationTo: this.locationTo,
        truckTypeId: this.truckTypeId,
        noTransport: this.noTransport,
      };
    },
    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl;
    },
    // https://dev.azure.com/smartreturdev/Portal/_workitems/edit/35/
    hidePricesSveCatC() {
      return (
        this.customer.abcCode === "C" &&
        this.customer.company === "SVE" &&
        this.isTO
      );
    },
  },

  methods: {
    async getProducts() {
      this.isLoading = true;

      try {
        const params = {
          portalType: "PORTAL",
          transactionType: this.transactionType,
        };

        if (this.isExternal) {
          params.isExternal = this.isExternal;
        }

        if (this.truckTypeId) {
          params.truckTypeId = this.truckTypeId;
        }

        if (this.orderId) {
          params.orderId = this.orderId;
        }

        if (this.noTransport) {
          params.transporterId = "DIREKTE";
        }

        let products = [];

        if (this.isGL) {
          if (
            this.locationTo &&
            this.locationTo.customerId === this.location.customerId
          ) {
            params.transactionType = "OO";
          }

          let poolingProducts = [];
          const commissionProducts = [];

          if (this.locationTo) {
            poolingProducts = await http.get("AvailableProducts", {
              params: {
                ...params,
                locationId: this.location.id,
                isPooling: this.locationTo.isPooling,
                isCommission: this.locationTo.isCommission,
              },
            });
          }

          products = poolingProducts.concat(commissionProducts);
        } else {
          products = await http.get("AvailableProducts", {
            params: {
              ...params,
              locationId: this.location.id,
            },
          });
        }

        this.products = map(products, (product) => {
          return {
            ...product,
            ...this.tryGetQuantityFromValue(product),
          };
        });
      } catch {
        this.requestFailed = true;
      }
      this.isLoading = false;
      // this.$emit("ready")
    },

    // https://dev.azure.com/smartreturdev/Portal/_workitems/edit/38/
    // https://dev.azure.com/smartreturdev/Portal/_workitems/edit/104/ - always use product.qtyMax, correct max is set in api
    getMax(product) {
      let max = !product.qtyFree ? product.qtyMaxOrder : 9999;

      if (
        this.isTO &&
        this.location.showInternalStock &&
        product.isCommission
      ) {
        const qtyStock = product.qtyStock * -1;
        max =
          qtyStock < 0 ? 0 : qtyStock >= 0 && qtyStock < max ? qtyStock : max;
      }

      return max;
    },

    getProductQtyMin(product) {
      return this.allowNegativeVolume && product.qtyFree
        ? -9999
        : product.quantityExtra
    },

    setQuantity100(product) {
      if (!product.isBlocked) {
        let quantity = product.qtyMaxOrder;

        // https://dev.azure.com/smartreturdev/Portal/_workitems/edit/38/
        if (
          this.isTO &&
          this.location.showInternalStock &&
          product.isCommission
        ) {
          const qtyStock = product.qtyStock * -1;
          quantity =
            qtyStock < 0
              ? 0
              : qtyStock >= 0 && qtyStock < quantity
                ? qtyStock
                : quantity;
        }

        // "snap" to highest possible
        if (!product.qtyFree && quantity > 0 && product.qtyPick > 0) {
          quantity = Number.parseInt(quantity / product.qtyPick, 10) * product.qtyPick;
        }
        this.setQuantity(product, quantity);
      }
    },

    setQuantity0(product) {
      // if (product.quantity > 0) {
      this.setQuantity(product, 0);
      // }
    },

    setQuantity(product, quantity) {
      product.quantity = quantity;
      // const products = filter(this.products, product => product.quantity > 0)
      this.emitInputEvent();
    },

    emitInputEvent() {
      this.$emit("input", this.products);
    },

    tryGetQuantityFromValue(product) {
      const match = find(this.value, (value) => {
        return key(value) === key(product);
      });
      return {
        quantity: get(match, "quantity", 0),
        quantityExtra: get(match, "quantityExtra", 0),
      };
    },

    numberFormat(number, decimals) {
      return new Intl.NumberFormat(this.$i18n.locale.replace('-sw', ''), {
        minimumFractionDigits: decimals,
      }).format(number);
    },
  },

  watch: {
    loadTriggers: {
      handler: async function() {
        await this.getProducts();
      },
      immediate: true,
    },

    value: function(val) {
      forEach(this.products, (p) => {
        const match = find(val, (v) => key(v) === key(p));
        p.quantity = match ? match.quantity : 0;
        p.quantityExtra = match ? match.quantityExtra : 0;
      });
    },
  },
};
</script>